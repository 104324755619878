import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import CollapsibleSidebar from "../../../components/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Define types for the API response
interface Content {
    id: number;
    title: string;
    main_code: string;
    code: string;
    description: string;
    image: string | null;
}

interface ApiResponse<T> {
    data: T[];
    totaldata: number;
}

const Admin = () => {
    const [contents, setContents] = useState<Content[]>([]);
    const [newContent, setNewContent] = useState({
        title: "",
        main_code: "",
        code: "",
        description: "",
        image: "",
    });
    const [editContent, setEditContent] = useState<any | null>(null);
    const [userMode, setUserMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState<number | null>(null);
    const token = localStorage.getItem("token");

    // Helper function to generate slug from title
    const generateSlug = (text: string) => {
        return text
            .toLowerCase()
            .replace(/[^a-z0-9\s]/g, "") // Remove special characters
            .trim()
            .replace(/\s+/g, "-"); // Replace spaces with hyphens
    };

    // Handle form changes in the add modal
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setNewContent((prev) => {
            const updatedContent = { ...prev, [name]: value };

            // Automatically update the code if the title is changed
            if (name === "title") {
                updatedContent.code = generateSlug(value);
            }

            return updatedContent;
        });
    };

    // Handle form changes in the edit modal
    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setEditContent((prev: any) => {
            const updatedContent = { ...prev, [name]: value };

            // Automatically update the code if the title is changed
            if (name === "title") {
                updatedContent.code = generateSlug(value);
            }

            return updatedContent;
        });
    };

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append('title', newContent.title);
            formData.append('main_code', newContent.main_code);
            formData.append('code', newContent.code);
            formData.append('description', newContent.description);
            formData.append('image', newContent.image);

            await axios.post(
                `${process.env.REACT_APP_URL}qubisa/manual/admin/admin-content/create`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setShowModal(false); // Close the modal
            await fetchContents(); // Fetch updated content list
        } catch (error) {
            console.error("Error saving new content:", error);
        }
    };

    // Handle updating existing content
    const handleUpdate = async () => {
        if (!editContent) return;

        try {
            const formData = new FormData();
            formData.append('title', editContent.title);
            formData.append('main_code', newContent.main_code);
            formData.append('code', editContent.code);
            formData.append('description', editContent.description);
            formData.append('image', newContent.image);
            await axios.put(
                `${process.env.REACT_APP_URL}qubisa/manual/admin/admin-content/update/${editContent.id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setShowModalEdit(false); // Close the edit modal
            await fetchContents(); // Fetch updated content list
        } catch (error) {
            console.error("Error updating content:", error);
        }
    };

    const handleDelete = async () => {
        if (!selectedContentId) return;

        try {
            await axios.delete(
                `${process.env.REACT_APP_URL}qubisa/manual/admin/admin-content/delete/${selectedContentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setShowModalDelete(false); // Close the delete modal
            await fetchContents(); // Fetch updated content list
            setSelectedContentId(null); // Reset selected content ID
        } catch (error) {
            console.error("Error deleting content:", error);
        }
    };

    // Fetch contents from API (existing logic)
    const fetchContents = async () => {
        try {
            const response = await axios.get<ApiResponse<Content>>(
                `${process.env.REACT_APP_URL}qubisa/manual/admin/admin-content`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setContents(response.data.data);
        } catch (error) {
            console.error("Error fetching admin content:", error);
        }
    };
    const handleEditClick = (content: Content) => {
        setEditContent({
            ...content,
            // imagePreview: content.image // Add an `imagePreview` for the existing image
        });
        setShowModalEdit(true);
    };

    const handleImageUploadEdit = (event: any) => {
        if (!event.target.files?.length) {
            return;
        }
        
        setNewContent((prevState:any) => ({ ...prevState, image: event.target.files[0] }));
    };

    const handleImageUpload = (event: any) => {
        if (event.target.files?.length) {
            setNewContent((prevState) => ({ ...prevState, image: event.target.files[0] }));
        }
    };
    useEffect(() => {
        if (token) {
            fetchContents();
            if (token === "user") {
                setUserMode(true);
            }
        }
    }, [token]);

    // Other existing logic...

    return (
        <div className="d-flex">
            <div>
                <CollapsibleSidebar />
            </div>
            <div className="h-[100vh] overflow-y-auto ps-4">
                <div className="container mt-4">

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h3>Admin Enterprise</h3>
                                <Button hidden={userMode} onClick={() => setShowModal(true)}>Add New Content</Button>
                            </div>
                            <ul className="list-group">
                                {contents.map((content) => (
                                    <div className="d-flex align-items-center">
                                        <li
                                            key={content.id}
                                            id={content.code}
                                            className="list-group-item w-100 mb-4"
                                            style={{ height: "500px" }}
                                        >
                                            <div className="mt-2">
                                                <p>{content.title}</p>
                                                <p>{content.description}</p>
                                                {content.image && (
                                                    <img
                                                        src={`${content.image}`}
                                                        alt="Content"
                                                        className="img-fluid"
                                                        style={{ maxWidth: "100%", maxHeight: "auto" }}
                                                    />
          )}
                                            </div>
                                        </li>
                                        <li className="p-4">
                                            <div className=" d-flex flex-column gap-2">
                                                <Button onClick={() => handleEditClick(content)}>Edit</Button>
                                                <Button onClick={() => {
                                                    setSelectedContentId(content.id);
                                                    setShowModalDelete(true);
                                                }}>Delete</Button>

                                            </div>
                                        </li>

                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* Existing UI logic */}
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Content</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formImage" className="mb-3">
                                    <Form.Label>Image</Form.Label>
                                    
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />

                                </Form.Group>
                                <Form.Group controlId="formTitle" className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={newContent.title || ""}
                                        onChange={handleChange}
                                        placeholder="Enter title"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formCode" className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        type="text"
                                        name="code"
                                        value={newContent.code || ""}
                                        readOnly // Make this field read-only
                                        placeholder="Code will be generated automatically"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formMainCode" className="mb-3">
                                    <Form.Label>Main Code</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        type="text"
                                        name="main_code"
                                        value={newContent.main_code = "admin-enterprise"}
                                        onChange={handleChange}
                                        placeholder="Enter main code"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formDescription" className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        value={newContent.description || ""}
                                        onChange={handleChange}
                                        placeholder="Enter description"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Content</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formEditImage" className="mb-3">
                                    <Form.Label>Image</Form.Label>
                                    <Form.Group controlId="formImage" className="mb-3">
                                        {editContent?.imagePreview && (
                                            <div className="mb-3">
                                                <img
                                                    // This will correctly display the image preview in Base64 format
                                                    src={editContent.imagePreview.startsWith('data:image')
                                                        ? editContent.imagePreview
                                                        : `data:image/jpeg;base64,${editContent.imagePreview}`} // Fallback to Base64 if it's a string without the prefix
                                                    alt="Preview"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        )}
                                        <Form.Control
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageUploadEdit}
                                    />

                                    </Form.Group>
                                </Form.Group>
                                <Form.Group controlId="formEditTitle" className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={editContent?.title || ""}
                                        onChange={handleEditChange}
                                        placeholder="Enter title"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEditCode" className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        type="text"
                                        name="code"
                                        value={editContent?.code || ""}
                                        readOnly // Make this field read-only
                                        placeholder="Code will be generated automatically"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEditMainCode" className="mb-3">
                                    <Form.Label>Main Code</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        type="text"
                                        name="main_code"
                                        value={editContent?.main_code || ""}
                                        onChange={handleEditChange}
                                        placeholder="Enter main code"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEditDescription" className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        value={editContent?.description || ""}
                                        onChange={handleEditChange}
                                        placeholder="Enter description"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModalEdit(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleUpdate}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showModalDelete} onHide={() => setShowModalDelete(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Content</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this content?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModalDelete(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            </div>


        </div>
    );
};

export default Admin;

