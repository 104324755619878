import React, { ReactNode } from "react";
import "./styles/global.scss";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ranking from "./pages/Ranking";
import MyPoin from "./pages/MyPoin";
import AllPost from "./pages/AllPost";
import History from "./pages/History";
import Admin from "./pages/ManualGuide/Admin";
import Student from "./pages/ManualGuide/Student";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap";

// Define props for AppContainer
type AppContainerProps = {
  children: ReactNode;
};

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const location = useLocation();

  // Get the `theme` query parameter from the URL
  const theme = new URLSearchParams(location.search).get("theme");

  // Set the class based on the theme
  const themeClass = theme === "white" ? "body-white" : "body-dark";

  return (
    <div className={`${themeClass} w-screen h-[100vh] overflow-y-auto`}>
      {children}
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="/" element={<AllPost />} />
          <Route path="/my-poin" element={<MyPoin />} />
          <Route path="/history" element={<History />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/manual-guideline/admin-enterprise" element={<Admin />} />
          <Route path="/manual-guideline/student-view" element={<Student />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
