import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChevronLeft, FaChevronRight, FaUserLock, FaUserGraduate } from "react-icons/fa";
import "./style.css"
import { useNavigate } from "react-router-dom";

// Define the type for a single category
interface Category {
  id: number;
  title: string;
  main_code: string;
}

// Define the type for a single subcategory
interface SubCategory {
  id: number;
  title: string;
  code: string;
  main_code: string;
}

// Define the structure of the API response
interface ApiResponse<T> {
  data: T[];
  totaldata: number;
}

// Fetch categories
const fetchCategories = async (token: string): Promise<Category[]> => {
  const response = await axios.get<ApiResponse<Category>>(
    `${process.env.REACT_APP_URL}qubisa/manual/admin/manual-category`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

// Fetch subcategories
const fetchSubCategories = async (token: string): Promise<SubCategory[]> => {
  const response = await axios.get<ApiResponse<SubCategory>>(
    `${process.env.REACT_APP_URL}qubisa/manual/admin/manual-subcategory`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

const CollapsibleSidebar = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      if (!token) {
        const token = localStorage.setItem("token", "user");
        return;
      }

      try {
        const categories = await fetchCategories(token);
        const subCategories = await fetchSubCategories(token);
        setCategories(categories);
        setSubCategories(subCategories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleCategory = (categoryId: number) => {
    setExpandedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleNavigate = (path: string) => {
    navigate(path); // Navigate to the given path
  };

  return (
    <div className="d-flex h-[100vh]">
      <div
        className={`sidebar bg-light border-end p-3 ${isCollapsed ? "collapsed" : ""
          }`}
        style={{
          transition: "width 0.3s",
          width: isCollapsed ? "80px" : "250px",
        }}
      >
        <button
          className="btn btn-primary mb-3"
          onClick={toggleSidebar}
          style={{
            position: "relative",
            top: "10px",
            left: isCollapsed ? "20px" : "210px",
          }}
        >
          {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
        </button>

        <div className="sidebar-header text-center">
          <h5 className={`text-black ${isCollapsed ? "d-none" : ""}`}>
            Manual Guideline
          </h5>
        </div>

        <ul className="nav flex-column">
          {categories.map((category) => (
            <li key={category.id} className="nav-item">
              <a
                className="nav-link title"
                href="#"
                onClick={() => toggleCategory(category.id)}
              >
                <FaUserLock className="me-2" />
                <div
                  className="nav-link p-0"
                  onClick={() =>
                    handleNavigate(`/manual-guideline/${category.main_code}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <span className={`${isCollapsed ? "d-none" : ""}`}>
                    {category.title}
                  </span>
                </div>
                {/* <a href={category.main_code} className={`${isCollapsed ? "d-none" : ""}`} style={{ textDecoration: "none" }}>
                  {category.title}
                </a> */}
                <i
                  className={`fa ${expandedCategory === category.id
                    ? "fa-chevron-down"
                    : "fa-chevron-right"
                    }`}
                  style={{ float: "right" }}
                ></i>
              </a>
              <ul
                className={`nav flex-column ps-4 ${expandedCategory === category.id ? "show" : "collapse"
                  }`}
              >
                {subCategories
                  .filter((sub) => sub.main_code === category.main_code)
                  .map((sub) => (
                    <li key={sub.id} className="nav-item">
                      <div
                        className="nav-link"
                        onClick={() =>
                          handleNavigate(`/manual-guideline/${category.main_code}#${sub.code}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <span className={`${isCollapsed ? "d-none" : ""}`}>
                          {sub.title}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
